/*!* 기본적으로 큰 화면에서 데스크탑 메뉴를 숨기지 않음 *!*/
/*.desktop-menu {*/
/*    display: flex;*/
/*}*/

/*!* 모바일 메뉴는 숨김 *!*/
/*.mobile-menu {*/
/*    display: none;*/
/*}*/
/*!* Menu 아이템이 동일한 너비를 차지하도록 설정 *!*/
/*.ant-menu-horizontal {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    width: 100%; !* 전체 너비 사용 *!*/
/*}*/

/*!* Menu.Item 간격을 줄이고, 텍스트가 생략되지 않게 설정 *!*/
/*.ant-menu-item {*/
/*    flex-grow: 1; !* 모든 항목이 동일한 크기로 확장 *!*/
/*    white-space: nowrap; !* 텍스트 줄바꿈 방지 *!*/
/*    padding: 0 15px; !* 패딩을 줄여서 더 많은 항목을 한 줄에 표시 *!*/
/*}*/
/*!* CSS 파일에서 Menu.Item의 텍스트 색상 변경 *!*/
/*.ant-menu-horizontal > .ant-menu-item {*/
/*    color: #201261 !important; !* 원하는 색상으로 변경 *!*/
/*}*/
/*!* 큰 화면에서는 수평 메뉴를 보여주고, 모바일 메뉴는 숨김 *!*/
/*@media (max-width: 768px) {*/
/*    .desktop-menu {*/
/*        display: none;*/
/*    }*/

/*    .mobile-menu {*/
/*        display: flex;*/
/*    }*/
/*}*/
