html, body {
  margin: 0;
  padding: 0;
}
body, button, input, textarea {
  font-family: 'NanumSquareR',sans-serif;
}
.ant-collapse-content-box {
  padding: 0;
}



.container {
  min-height: 100vh;
  padding-top: 80px;
  position: relative;
  overflow: hidden;
  background-image: url('@/assets/images/home/bg.png'); /* 경로는 실제 이미지 경로로 변경 */
  background-size: cover; /* 배경 이미지가 화면을 꽉 채우도록 설정 */
  background-position: center; /* 이미지를 중앙에 위치시킴 */
  background-repeat: no-repeat; /* 이미지 반복을 방지 */
}

/* 모바일 대응 */
@media (max-width: 768px) {
  .container {
    background-image: url('@/assets/images/home/mobileBg.png'); /* 경로는 실제 이미지 경로로 변경 */
  }
}
